.container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: #273464;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 0;
}

.response {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  max-width: 90%;
  word-wrap: break-word;
}

.userMessage {
  background-color: #DCF8C6;
  align-self: flex-end;
  margin-left: 10%;
}

.assistantMessage {
  background-color: #f9f9f9;
  align-self: flex-start;
  margin-right: 10%;
}

.form {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #273464;
  padding: 10px 0;
  z-index: 1000;
}

.inputContainer {
  position: relative;
  flex: 1;
  display: flex;
  padding: 0 20px;
}

.input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin: 0;
  resize: none;
  font-size: 16px;
}

.sendIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.messageDate {
  display: flex;
  flex-direction: row-reverse;
  color: gray;
  margin-top: 10px;
  font-size: 10px;
  margin-left: auto;
  margin-right: 0;
}

.chatContainer {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aeaaaa #f0f0f0;
}

.chatContainer::-webkit-scrollbar {
  width: 8px;
}

.chatContainer::-webkit-scrollbar-thumb {
  background-color: #aeaaaa;
  border-radius: 4px;
}

.chatContainer::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 5px;
  margin-top: 5px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
