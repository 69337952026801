
.header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 60px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.name {
  font-weight: bold;
}

.status {
  font-size: 12px;
  color: #888888;
}
